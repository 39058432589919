// ** React Imports
import { useState, useEffect, createContext } from 'react'
import { useSelector } from 'react-redux'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Demo Language Data
import messagesFrDemo from '@assets/.DEMO/data/locales/fr.json'
import messagesEnDemo from '@assets/.DEMO/data/locales/en.json'

// ** Core Language Data
import appFr from '@assets/data/locales/app/fr.json'
import appEn from '@assets/data/locales/app/en.json'

// ** Menu Language Data
import menuFr from '@assets/data/locales/menu/fr.json'
import menuEn from '@assets/data/locales/menu/en.json'

// ** Messages Language Data
import messagesFr from '@assets/data/locales/messages/fr.json'
import messagesEn from '@assets/data/locales/messages/en.json'

// ** Menu msg obj
const menuMessages = {
  fr: { ...messagesFrDemo, ...appFr, ...menuFr, ...messagesFr, },
  en: { ...messagesEnDemo, ...appEn, ...menuEn, ...messagesEn, },
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  // ** Store
  const language = useSelector(state => state.app.language || 'fr')

  useEffect(() => {
    switchLanguage(language)
  }, [language])

  // ** States
  const [locale, setLocale] = useState('fr')
  const [messages, setMessages] = useState(menuMessages['fr'])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale={'fr'}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }