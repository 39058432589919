import { createSlice } from '@reduxjs/toolkit'
import initialState from "@configs/appConfig"

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    handleLanguage: (state, action) => { state.language = action.payload },
  },
})

export const {
  handleLanguage,
} = appSlice.actions

export default appSlice.reducer