import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios"
import initialState from "@configs/apiConfig"

const executeCommand = createAsyncThunk(
  'api/execute',
  async (params, thunkAPI) => {
// console.log(params)
// console.log(thunkAPI.getState().api.conf.method)
// console.log(thunkAPI.getState().api.conf.url)
    const {command = '', ...commandParams} = params
    return await axios.request(
      {
        method: thunkAPI.getState().api.conf.method,
        baseURL: thunkAPI.getState().api.conf.url,
        url: command,
        timeout: thunkAPI.getState().api.conf.timeout,
        params: {...{token: thunkAPI.getState().api.token}, ...commandParams},
      },
    )
    .then(response => response.data )
    .catch((error) => {
// console.error(error)
      return { errors:['apiUnavailable'], data: [], token: '', }
    })
  }
)

export const apiSlice = createSlice({
  name: 'api',
  initialState: initialState,
  reducers: {
    loginWithJWT: (state, action) => {
      const { user=null, company=null} = action.payload
      return { ...state, user, company, }
    },
    logoutWithJWT: (state, action) => {
      return { ...state, user: null, company: null, }
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(executeCommand.pending, (state, { payload }) => {})
    .addCase(executeCommand.fulfilled, (state, { payload }) => {
      if(payload.token)
        state.token = payload.token
    })
    .addCase(executeCommand.rejected, (state, action) => {})
  },
})

export const { 
  loginWithJWT,
  logoutWithJWT,
 } = apiSlice.actions

export { executeCommand, }

export default apiSlice.reducer