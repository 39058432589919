import { applyMiddleware, compose, combineReducers } from "redux"
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createDebounce from "redux-debounced"
import thunk from "redux-thunk"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import apiReducer from './api/apiSlice'
import appReducer from './app/appSlice'
import layoutReducer from './layout/layoutSlice'



import auth from './.DEMO/reducers/auth'
import navbar from './.DEMO/reducers/navbar'
// import layout from './.DEMO/reducers/layout'
import chat from '@src/views/.DEMO/apps/chat/store/reducer'
import todo from '@src/views/.DEMO/apps/todo/store/reducer'
import users from '@src/views/.DEMO/apps/user/store/reducer'
import email from '@src/views/.DEMO/apps/email/store/reducer'
import invoice from '@src/views/.DEMO/apps/invoice/store/reducer'
import calendar from '@src/views/.DEMO/apps/calendar/store/reducer'
import ecommerce from '@src/views/.DEMO/apps/ecommerce/store/reducer'
import dataTables from '@src/views/.DEMO/tables/data-tables/store/reducer'



const middlewares = [thunk, createDebounce()]

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'layout',
  ],
}

const appPersistConfig = {
  key: 'app',
  storage,
  whitelist: [
    'language',
  ],
}

const apiPersistConfig = {
  key: 'api',
  storage,
  whitelist: [
    'token',
  ],
}

const rootReducer = combineReducers({
  api: persistReducer(apiPersistConfig, apiReducer),
  app: persistReducer(appPersistConfig, appReducer),
  layout: layoutReducer,


  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  // layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
})


const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  enhancers: [compose(applyMiddleware(...middlewares))],
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }),
})
const persistor = persistStore(store)

export {store, persistor}