import { createSlice } from '@reduxjs/toolkit'
import initialState from "@configs/layoutConfig"

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    handleRTL: (state, action) => { state.isRTL = action.payload },
    handleSkin: (state, action) => { state.skin = action.payload },
    handleRouterTransition: (state, action) => { state.routerTransition = action.payload },
    handleType: (state, action) => { state.type = action.payload },
    handleContentWidth: (state, action) => { state.contentWidth = action.payload },
    handleMenu: (state, action) => { state.menu = { ...state.menu, ...action.payload } },
    handleNavbar: (state, action) => { state.navbar = { ...state.navbar, ...action.payload } },
    handleFooter: (state, action) => { state.footer = { ...state.footer, ...action.payload } },
    handleCustomizer: (state, action) => { state.customizer = action.payload },
    handleScrollTop: (state, action) => { state.scrollTop = action.payload },
  },
})

export const {
  handleRTL,
  handleSkin,
  handleRouterTransition,
  handleType,
  handleContentWidth,
  handleMenu,
  handleNavbar,
  handleFooter,
  handleCustomizer,
  handleScrollTop,
} = layoutSlice.actions


export default layoutSlice.reducer